import React, { useEffect, useMemo, useState } from 'react';
import { getDate } from 'utils';
import { DateCustom } from 'types/date';
import logoAWS from './AWS-logo.svg';
import logoKlika from './Klika-logo.svg';
import logoSubeca from './subeca-logo.svg';

const currDate = new Date();

const Layout = ({ children }: {children: React.ReactNode}) => {
  const [timerDate, setTimerDate] = useState(currDate);
  const date: DateCustom = useMemo(() => {
    return getDate(timerDate)
  }, [timerDate]);

  useEffect(() => {
    const interval = setInterval(() => {setTimerDate(new Date())}, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="page-wrapper">
      <header>
        <div className="header-col">
          <div className="date">
            <div className="date__date">{`${date.mon} ${date.d}, ${date.y}`}</div>
            <div className="date__separator"></div>
            <div className="date__time">{`${date.h}:${date.min}`}</div>
          </div>
        </div>
        <div className="header-col">
          <div className="page-header">
            <div className="page-header__title">Water Consumption and Valve Status</div>
            <div className="page-header__separator"></div>
            <div className="page-header__subtitle">Meter ID: e455ccb5-aac8-433c-1df8-d689d05433e4</div>
          </div>
        </div>
        <div className="header-col">
          <div className="pseudo-account"></div>
        </div>
      </header>

      {children}

      <footer className="footer">
        <div className="footer-col">
          <a href="https://klika-tech.com" className="logo-footer">
            <img src={logoKlika} alt="Klika Tech logo" />
          </a>
        </div>
        <div className="footer-col">
          <a href="https://www.subeca.com/" className="logo-footer">
            <img src={logoSubeca} alt="Subeca logo" />
          </a>
        </div>
        <div className="footer-col">
          <a href="https://aws.amazon.com/" className="logo-footer">
            <img src={logoAWS} alt="AWS logo" />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
